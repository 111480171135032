$primary: #8efffb;
$light: #f1f1f1;
@import "~bootstrap/scss/bootstrap";

@font-face {
	font-family: "rischeM";
	src: url("../static/fonts/rische.ttf");
}

@font-face {
	font-family: "CocoSharp";
	src: url("../static/fonts/coco.otf");
}

* {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
	color: white;
}

html,
body {
	overflow: hidden;
	height: 100vh;
	background: #0e0e0e;
}
.webgl {
	position: fixed;
	top: 0;
	left: 0;
	outline: none;
	z-index: 0;
}
#topcont {
	position: absolute;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 1;
	h2 {
		font-size: 1.1rem;
	}
	img {
		height: 100%;
	}
	p {
		font-style: italic;
	}
	.censored {
		font-size: 0.7rem;
		color: black;
	}
}
#bottomcont {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	.meanwhile {
		padding-top: 5rem;
		img {
			height: 20px;
		}
	}
}
.hiddentext {
	opacity: 0;
	&.revealtext {
		transition: opacity 1s ease-in-out;
		opacity: 1;
	}
}
@media screen and (min-width: 992px) {
	body {
		background-color: transparent;
	}
	.webgl {
		z-index: -1;
	}
	hr {
		width: 3rem;
	}
}

@media only screen and (max-width: 921px) {
	.one {
		width: 100%;
		position: absolute;
		top: 0px;
		z-index: 2;
	}

	.clicklogo {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 1.5rem;
	}

	.image {
		padding-top: 3rem;
	}

	.title h1 {
		font-family: rischeM;
		color: #f1f1f1;
		/* font-weight: 100; */
		font-size: 2.5rem;
		text-align: center;
		padding-top: 1rem;
		line-height: 2.5rem;
	}

	.cont h2 {
		font-family: CocoSharp;
		color: #f1f1f1;
		text-align: center;
		padding: 1rem 2.7rem;
		font-size: 1.1rem;
		max-width: 500px;
		margin: auto;
	}

	.two {
		position: absolute;
		bottom: 2.5rem;
		width: 100%;
		z-index: 2;
	}

	.text-cont-down {
		display: flex;
		justify-content: center;
	}

	.text-cont-down h2 {
		font-family: CocoSharp;
		color: #f1f1f1;
		text-align: center;
		font-size: 1.2rem;
	}

	.three {
		display: none;
	}

	.four {
		display: none;
	}
	.two a {
		color: #f1f1f1;
		text-align: center;
		font-family: 'CocoSharp';
		font-size: 1.2rem;
	}
}
@media only screen and (min-width: 921px) {
	.one {
		display: none;
	}

	.two {
		display: none;
	}

	.three .logo {
		padding-top: 2rem;
		padding-left: 7%;
	}

	.four .title h1 {
		font-family: rischeM;
		color: #f1f1f1;
		/* font-weight: 100; */
		font-size: 4rem;
		/* text-align: center; */
		padding-top: 1rem;
		padding-left: 7vw;
		line-height: 3.7rem;
	}

	.four .text-cont-down h2 {
		font-family: CocoSharp;
		color: #f1f1f1;
		padding: 1rem 2.7rem;
		font-size: 1.3rem;
		max-width: 40vw;
		padding-left: 7vw;
	}

	.four {
		height: 60vh;
		position: absolute;
		bottom: 0%;
	}

	.four a {
		color: #f1f1f1;
		margin-left: 2rem;
		font-family: 'CocoSharp';
	}
}
